<template>
  <div class="slider-wrap">
      <div class="corner-1"></div>
      <div class="corner-2"></div>
      <div class="corner-3"></div>
      <div class="img-box">
        <picture>
          <source media="(max-width: 767px)" :srcset="$get(contentData, 'data.list.0.image.0.devices.moile')" />
          <source data-scroll-speed="-1" media="(max-width: 1023px)"
                  :srcset="$get(contentData, 'data.list.0.image.0.devices.tablet')" />
          <img data-scroll data-scroll-speed="-1" :src="$get(contentData, 'data.list.0.image.0.devices.desktop')"
               :alt="title" />
        </picture>
      </div>
  </div>
</template>
<script>

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination, Navigation, EffectCreative, Autoplay } from 'swiper';
import ExplorerSec from '../../UI/ExplorerSec.vue';



export default {
  name: 'main_slider',
  props: ['contentData'],
  components: {
    Swiper,
    SwiperSlide,
    ExplorerSec
  },
  setup() {
    return {
      modules: [Pagination, Navigation, EffectCreative, Autoplay],
    };
  },
};
</script>
<style scoped lang="scss">
.slider-wrap {
  position: relative;
  margin-top: 144px;
}

.corner-1 {
  position: absolute;
  top: -2px;
  left: 0;
  width: 310px;
  height: 74px;
  background-color: #fff;
  z-index: 5;
}

.corner-2 {
  position: absolute;
  top: -2px;
  right: 0;
  width: 21px;
  height: 82px;
  background-color: #fff;
  z-index: 5;
}

.corner-3 {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 185px;
  height: 65px;
  background-color: #fff;
  z-index: 5;
}

.corner-3:after {
  position: absolute;
  content: '';
  right: -36px;
  bottom: -1px;
  width: 37px;
  height: 37px;
  background-color: #fff;
}

::v-deep.swiper-slide {
  cursor: pointer;
}

.img-box {
  position: relative;
  width: 100%;
  padding-top: 60.1%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
}

.slider-prev {
  position: absolute;
  top: 50%;
  left: 85px;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  transition: .4s;

  &:hover {
    transform: translate(-10px, -50%);
  }

}


.slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 85px;
  z-index: 2;
  cursor: pointer;
  transition: .4s;

  &:hover {
    transform: translate(10px, -50%);
  }
}

::v-deep .swiper-pagination {
  position: absolute;
  display: flex;
  bottom: 58px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  justify-content: center;

  &.swiper-pagination-lock{
    display: none;
  }


  .swiper-pagination-bullet {
    display: block;
    width: 96px;
    height: 6px;
    background-color: rgba(255, 255, 255, 0.5);
    transition: .4s;
    margin-right: 7px;
    cursor: pointer;
    display: none;
  }

  .swiper-pagination-bullet-active-main,
  .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet-active-prev {
    flex: none;
    display: block;
  }

  .swiper-pagination-bullet:last-child {
    margin-right: 0;
  }

  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 71px 320px 124px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  * {
    color: #fff;
  }

  .sub-ttl,
  .teaser {
    font-size: 34px;
    line-height: 40px;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: transform .62s .1s cubic-bezier(0.34, 0, 0.18, 1);
  }

  .teaser:hover {
    transform: scale(0.9);
  }

  .title {
    font-size: 144px;
    line-height: 151px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    will-change: transform;

    transition: transform .62s .1s cubic-bezier(0.34, 0, 0.18, 1);
  }

  .title:hover {
    transform: scale(0.9);
  }
}

// animation
// .swiper-slide-next .img-box {
//    -webkit-clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%);
//    clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%);
//    transform: translateX(-70%);
//    transition: 0.6s;
// }

// .swiper-slide-active .img-box {
//    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//    transition: 0.6s;
// }


.img-box {
  width: 100%;
  padding-top: 70%;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1900px) {
  .slider-wrap{
    margin-top: 199px;
  }
  .corner-1 {
    position: absolute;
    width: 230px;
    height: 56px;
  }

  .corner-2 {
    position: absolute;
    width: 17px;
    height: 61px;
  }

  .corner-3 {
    position: absolute;
    width: 138px;
    height: 48px;
  }

  .corner-3:after {
    position: absolute;
    width: 27px;
    height: 27px;
    right: -26px;
  }

  .slider-prev {
    left: 34px;

    svg {
      display: block;
      width: 38px;
      height: 57px;
    }
  }

  .slider-next {
    right: 34px;

    svg {
      display: block;
      width: 38px;
      height: 57px;
    }
  }

  ::v-deep .swiper-pagination {
    bottom: 43px;

    .swiper-pagination-bullet {
      width: 72px;
      height: 5px;
    }
  }

  .content {
    padding: 54px 120px 93px;

    .title {
      font-size: 96px;
      line-height: 116px;
      -webkit-line-clamp: 3;
    }

    .sub-ttl,
    .teaser {
      max-width: 720px;
    }
  }

}

@media screen and (max-width: 1439px) {
  .slider-wrap{
    margin-top: 155px;
  }
  .corner-1 {
    position: absolute;
    width: 204px;
    height: 53px;
  }

  .corner-2 {
    position: absolute;
    width: 14px;
    height: 61px;
  }

  .corner-3 {
    position: absolute;
    width: 123px;
    height: 44px;
  }

  .corner-3:after {
    position: absolute;
    right: -22px;
    width: 23px;
    height: 23px;
  }


  .slider-prev {
    left: 30px;

    svg {
      display: block;
      width: 32px;
      height: 48px;
    }
  }

  .slider-next {
    right: 30px;

    svg {
      display: block;
      width: 32px;
      height: 48px;
    }
  }

  ::v-deep .swiper-pagination {
    bottom: 38px;

    .swiper-pagination-bullet {
      width: 64px;
      height: 4px;
    }
  }

  .content {
    padding: 48px 120px 82px;

    .title {
      font-size: 96px;
      line-height: 116px;
      -webkit-line-clamp: 3;
    }

    .sub-ttl,
    .teaser {
      max-width: 640px;
    }
  }

}

@media screen and (max-width: 1279px) {
  .slider-wrap{
    margin-top: 105px;
  }
  .corner-1 {
    position: absolute;
    width: 123px;
    height: 30px;
  }

  .corner-2 {
    position: absolute;
    width: 9px;
    height: 33px;
  }

  .corner-3 {
    position: absolute;
    width: 74px;
    height: 25px;
  }

  .corner-3:after {
    position: absolute;
    right: -13px;
    width: 14px;
    height: 14px;
  }

  .slider-prev {
    left: 18px;

    svg {
      display: block;
      width: 20px;
      height: 30px;
    }
  }

  .slider-next {
    right: 18px;

    svg {
      display: block;
      width: 20px;
      height: 30px;
    }
  }

  ::v-deep .swiper-pagination {
    bottom: 23px;

    .swiper-pagination-bullet {
      width: 38px;
      height: 2px;
    }
  }

  .content {
    padding: 28px 120px 49px;

    .title {
      font-size: 48px;
      line-height: 58px;
      -webkit-line-clamp: 3;
    }

    .sub-ttl,
    .teaser {
      max-width: 370px;
      font-size: 18px;
      line-height: 22px;
    }
  }

}

@media screen and (max-width: 767px) {
  .slider-wrap{
    margin-top: 20px;
  }
  .corner-1 {
    position: absolute;
    width: 187px;
    height: 11px;
  }

  .corner-2 {
    display: none;
  }

  .corner-3 {
    position: absolute;
    left: auto;
    right: 0;
    width: 188px;
    height: 11px;
  }

  .corner-3:after {
    display: none;
  }

  .img-box {
    padding-top: 0;
    height: 633px;
  }

  .slider-prev {
    left: 11px;

    svg {
      display: block;
      width: 24px;
      height: 36px;
    }
  }

  .slider-next {
    right: 11px;

    svg {
      display: block;
      width: 24px;
      height: 36px;
    }
  }

  ::v-deep .swiper-pagination {
    bottom: 38px;

    .swiper-pagination-bullet {
      width: 64px;
      height: 4px;
    }
  }

  .content {
    padding: 55px 60px 62px;

    .title {
      font-size: 34px;
      line-height: 40px;
      -webkit-line-clamp: 4;
    }

    .sub-ttl,
    .teaser {
      font-size: 16px;
      line-height: 19px;
      max-width: 250px;
    }
  }

}

@media screen and (max-width: 384px) {

}
</style>
